import React from "react";


export default function Employment(){
    return(
        <div>
            <div style={company}>
                <a href="https://www.bestpack.com" target="_blank">
                    <div style={company_name}>
                    Software Engineer | BestPack Packaging Systems
                    </div>
                </a>
                <div style={company_location}>Ontario,California</div>
                <div style={company_time}>March 2020 — Present</div>
                <div style={work_item}>
                    <ul>
                        <li>
                            Developed full stack web applications using spring boot
                            framework, react & MySQL and deployed them in Docker
                            containers.
                        </li>
                        <li>
                            Developed and tested REST APIs using for several projects using
                            Node.js, Spring boot & Azure functions.
                        </li>
                        <li>
                            Developed mobile applications for warehouse management using
                            React Native.
                        </li>
                        <li>
                            Worked with third party vendors to integrate their products using
                            REST and SOAP API.
                        </li>
                        <li>
                            Developed Embedded systems applications using FreeRTOS,
                            ESP-IDF & Arduino Framework
                        </li>
                    </ul>
                </div>
            </div>

            <div style={company}>
                <div style={company_name}>Software developer Intern | Lyles Center for Innovation and Entrepreneurship</div>
                <div style={company_location}>Fresno,California</div>
                <div style={company_time}>March 2019 — December 2019</div>
                <div style={work_item}>
                    <ul>
                        <li>
                            Developed Amazon Alexa skills to help navigate students during
                            University admissions.
                        </li>
                        <li>
                            Designed and developed robotic applications using Lidar and
                            sonar sensors, motors and utilizing Robot Operating System
                            (ROS).
                        </li>
                        <li>
                            Image processing robotic applications using camera and OpenCV.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}


const company = {
    
}

const company_name = {
    color: '#fafafc',
    fontWeight: 'bold',
    fontSize: '1.2em'
}

const company_location = {

}

const company_time = {

}

const work_item = {
    color:'#d9d9d9'
}
