import React from "react";

export default function Navigation(){
    return(
        <div className={'container-nav navbar sticky-top'}>
            <nav>
                <div className={'nav-list'}>
                    <a className={'navItem'} href={'#about_block'}>About</a>
                    <a className={'navItem'} href={'#work_block'}>Experience</a>
                    <a className={'navItem'} href={'#skills_block'}>Skills</a>
                    <a className={'navItem'} href={'#eduction_block'}>Education</a>
                    <a className={'navItem'} href={'#contact_block'}>Contact</a>
                </div>
            </nav>
        </div>
    )
}

