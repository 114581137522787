import logo from './logo.svg';
import './App.css';
import {useEffect} from "react";

import Main from "./components/Main";
import Navigation from "./components/Navigation";

function App() {
    useEffect(()=>{
      //window.document.body.style.background = "#111320"; //#111320 #141424
      // text : #b38b53
    },[]);
  return (

    <div>
        <Navigation/>
        <Main/>
    </div>
  );
}

export default App;
