import { Stack } from "@mui/material";
import Divider from '@mui/material/Divider';
import React from "react";

export default function Skills(){
    return(
        <div>
            <div className={'skill-section'}>
                <div className={'skill-section-title'}>Web Technlogies</div>
                <div className={'skill-itemset'}>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} justifyContent={'center'} 

                        divider={<Divider style={{ background: 'black' }} variant="middle" />}
                        >
                        <div className={'skill-item'}>Spring Boot</div>
                        <div className={'skill-item'}>NodeJs</div>
                        <div className={'skill-item'}>React</div>
                        <div className={'skill-item'}>React Native</div>
                        <div className={'skill-item'}>REST & SOAP Api development</div>
                    </Stack>

                </div>
            </div>

            <div className={'skill-section'}>
                <div className={'skill-section-title'}>Databases</div>
                <div className={'skill-itemset'}>
                    <div className={'skill-item'}>MySql</div>
                </div>
            </div>

            <div className={'skill-section'}>
                <div className={'skill-section-title'}>Embedded Systems</div>
                <div className={'skill-itemset'}>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}justifyContent={'center'} > 
                        <div className={'skill-item'}>FreeRTOS</div>
                        <div className={'skill-item'}>Arduino</div>
                        <div className={'skill-item'}>Basic Circuits and Electronics</div>
                        <div className={'skill-item'}>UART, SPI, I2C, MQTT</div>
                    </Stack>

                </div>
            </div>

            <div className={'skill-section'}>
                <div className={'skill-section-title'}>Dev-Ops</div>
                <div className={'skill-itemset'}>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} justifyContent={'center'} >
                    <div className={'skill-item'}>Docker</div>
                    <div className={'skill-item'}>Linux</div>
                    <div className={'skill-item'}>Git</div>
                    <div className={'skill-item'}>Shell Scripting</div>
                    <div className={'skill-item'}>Jenkins</div>
                    <div className={'skill-item'}>Microsoft Azure</div>
                    <div className={'skill-item'}>Amazon AWS</div>
                    </Stack>
                </div>
            </div>

            <div className={'skill-section'}>
                <div className={'skill-section-title'}>Languages</div>
                <div className={'skill-itemset'}>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} justifyContent={'center'} >
                        <div className={'skill-item'}>Java</div>
                        <div className={'skill-item'}>JavaScript</div>
                        <div className={'skill-item'}>C/C++</div>
                        <div className={'skill-item'}>Python</div>
                    </Stack>
                </div>
            </div>
        </div>
    )
}

