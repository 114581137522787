import React from "react";

export default function Education(){
    return (
        <div>
            <div style={{fontSize: '1.6em'}}>California State University, Fresno</div>
            <div>Bachelor's of Science</div>
            <div style={{fontWeight:'bold'}} >Computer Engineering</div>
            <div>Graduation:December 2019</div>
        </div>
    )
}
