import React from "react";
import ravi from '../resources/ravi.jpg'
import Employment from "./Employment";
import Skills from "./Skills";
import Education from "./Education";
//import linkedin from '../resources/LinkedIn.svg'
import resume from '../resources/resume.pdf'; 
import DownloadIcon from '@mui/icons-material/Download';
import { SvgIcon } from "@mui/material";

export default function Main(){
    return(

        <div className={'resume-container'}>
            {/* side  area */}


            {/* Main area */}
            <div className={'resume-main'}>

                <div className={'resume-main-section shadow'} id={'about_block'}>
                    <div style={{textAlign:'center'}}>
                        <img src={ravi} alt={'main-img'} style={{width:'40%', borderRadius:'0.5em', margin:'1em'}}/>
                        <div className={'resume-side-name'}>Ravi kumar Shah</div>
                        <div className={'resume-side-Title'}>Software Engineer</div>

                        
                        <p className={'resume-side-intro'}>
                            Innovative Software Engineer with over 3 years experience in back-end and 
                            front-end development, mobile applications and embedded systems. Adept in 
                            bringing forth expertise in design, development, testing, deployment and
                            maintenance of software systems. Proficient in Spring Boot, React, C++ with 
                            good background of DevOps best practices.

                        </p>

                        <div>
                            <a href={resume} download style={{color:'skyblue', fontStyle:'italic',marginBottom:2}}>
                                Resume
                                <SvgIcon component={DownloadIcon}></SvgIcon>
                                </a>
                            
                        </div>
                    </div>

                </div> {/* side area */}

                {/* Work Experience */}
                <div className={'resume-main-section shadow'} id={'work_block'}>
                    <div className={'resume-section-title'}>Work Experience</div>
                    <div className={'resume-section-body'}>
                        <Employment/>
                    </div>
                </div>

                {/* Skills */}
                <div className={'resume-main-section shadow'} id={'skills_block'}>
                    <div className={'resume-section-title'}> Skills</div>
                    <div className={'resume-section-body'}>
                        <Skills/>
                    </div>
                </div>

                {/* Eduction */}
                <div className={'resume-main-section shadow'} id={'eduction_block'}>
                    <div className={'resume-section-title'}>Education</div>
                    <div className={'resume-section-body'}>
                        <Education/>
                    </div>
                </div>

                <div className={'resume-main-section shadow'} id={'contact_block'}>
                    <div className={'resume-section-title'}>Contact</div>
                    <div className={'resume-section-body'}>
                        
                            <div style={{display:'flex', flexDirection:'column', textAlign:'center'}}>
                                <a style={{color:'white'}} href="mailto:info@ravikumarshah.com">info@ravikumarshah.com</a>
                                <a style={{color:'white'}} href="https://www.linkedin.com/in/ravikumarshah0075/">Linkedin</a>
                            </div>
                  
                    </div>
                </div>

              
            </div> {/* Main area */}

        </div>
    )
}
//#c78f41
